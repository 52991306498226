import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import BusinessInvolvementChart from '../../esgExposure/esgExposureComponents/BusinessInvolvementChart.component';
import { Typography } from '@mui/material';

interface BusinessInvolvementPanelProps {
  data: any;
}

const useStyles = makeStyles(() => ({
  imageGrid: {
    // width: '66%',
    display: 'flex-column',
    justifyContent: 'space-around',
    marginTop: '0.62rem',
  },
  individualImage: {
    // width: '33%',
    // display: 'flex-column',
    justifyContent: 'center',
  },
  businessInvolvementCharts: {
    display: 'flex',
    justifyContent: 'space-around',
    // width: '66%',
  },
  chartContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  },
  starsContainer: {
    verticalAlign: 'center',
  },
}));

interface BusinessInvolvementData {
  adultEntertainment: number;
  labourStandards: number;
  fossilFuels: number;
  tobacco: number;
  gambling: number;
  weapons: number;
  package: string;
}

function buildBusinessInvolvementData(data: any): BusinessInvolvementData {
  const panelData: BusinessInvolvementData = {
    adultEntertainment: 0,
    labourStandards: 0,
    fossilFuels: 0,
    tobacco: 0,
    gambling: 0,
    weapons: 0,
    package: 'esg_premium_package', // Default to the premium package if not available.
  };
  if (!data.data.length) return panelData;
  const currentData = data.data[0];
  if ('no data' in currentData) return panelData;
  if ('esg_subscription_package' in currentData)
    panelData.package = currentData.esg_subscription_package;
  if (panelData.package === 'esg_pure_package') return panelData;
  panelData.adultEntertainment =
    currentData.risksystem_leaves.policies['Adult Entertainment'];
  panelData.labourStandards =
    currentData.risksystem_leaves.policies['Labour Standards and Human Rights'];
  panelData.fossilFuels =
    currentData.risksystem_leaves.policies['Fossil Fuels'];
  panelData.tobacco = currentData.risksystem_leaves.policies['Tobacco'];
  panelData.gambling = currentData.risksystem_leaves.policies['Gambling'];
  panelData.weapons =
    currentData.risksystem_leaves.policies['Weapons & Firearms'];

  return panelData;
}

const BusinessInvolvementPanel: FC<BusinessInvolvementPanelProps> = (props) => {
  // const theme = useTheme();
  const { data } = props;
  const classes = useStyles();
  const panelData = buildBusinessInvolvementData(data);
  return panelData.package === 'esg_premium_package' ? (
    <GridItem
      card
      lg={12}
      // height="100%"
      cardStyle={{
        padding: '0.62rem',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'white',
      }}
    >
      <div>
        <Typography
          variant="h1"
          style={{
            fontSize: '1.50rem',
            width: '100%',
            fontWeight: 500,
            textAlign: 'left',
          }}
        >
          Business Involvement
        </Typography>
        <div className={classes.chartContainer}>
          {/* <div className={classes.businessInvolvementCharts}> */}
          <div className={classes.imageGrid}>
            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Adult Entertainment"
                value={panelData.adultEntertainment}
              />
            </div>

            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Labour Standards"
                value={panelData.labourStandards}
              />
            </div>
          </div>
          <div className={classes.imageGrid}>
            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Fossil Fuels"
                value={panelData.fossilFuels}
              />
            </div>

            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Tobacco"
                value={panelData.tobacco}
              />
            </div>
          </div>
          <div className={classes.imageGrid}>
            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Gambling"
                value={panelData.gambling}
              />
            </div>

            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Weapons & Firearms"
                value={panelData.weapons}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </GridItem>
  ) : null;
};

export default BusinessInvolvementPanel;
