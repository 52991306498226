import React, { ErrorInfo, ReactNode } from 'react';
import { flexify } from '../../utilities/cssMixins';
import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import FastAPIInstance from '../../services/risksystem3-public-api';
import FastAPIAuthService from '../../services/risksystem3-public-auth-service';

interface ErrorBoundaryProps {
  children: ReactNode;
}

type ErrorDetails = {
  message: string;
  url: string;
  stack: string;
  componentStack: string | null | undefined;
  userAgent: string;
  timestamp: string;
  userName: string;
  userId: string;
};
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  public state = {
    redirect: '',
    hasError: false,
  };
  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  // set the types for error  and info
  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error:', error, info);

    const user = FastAPIAuthService.getCurrentUser();
    const currentUrl = window.location.href;

    const errorDetails: ErrorDetails = {
      message: error.message,
      url: currentUrl,
      stack: 'Minified files do not have legible component stack traces', // error.stack,
      componentStack:
        'Minified files do not have legible component stack traces', // info.componentStack,
      userAgent: navigator.userAgent,
      timestamp: new Date().toISOString(),
      userName: user.user_name,
      userId: user.user_id,
    };

    const isProduction = process.env.NODE_ENV === 'production';

    // Only send if the user is 'external_administrator' or 'external_individual' in production
    if (
      isProduction &&
      (user.user_type === 'external_administrator' ||
        user.user_type === 'external_individual')
    ) {
      this.sendErrorToBackend(errorDetails);
    }
  }

  private sendErrorToBackend(errorDetails: Record<string, any>) {
    FastAPIInstance.post('v1/errors/send_console_error', errorDetails).catch(
      (err) => console.warn('Failed to send error log:', err),
    );
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    }
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ height: '80vh', width: '100%', ...flexify() }}>
          <Typography variant="h3">
            An error occurred, and a report has been submitted to the site
            administrator. Please try refreshing your page.
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
