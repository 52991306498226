import { FC, ReactElement } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CorporateGovernanceChart from '../../esgExposure/esgExposureComponents/CorporateGovernanceChart';
import EnvironmentalChart from '../../esgExposure/esgExposureComponents/EnvironmentalChart';
import SocialChart from '../../esgExposure/esgExposureComponents/SocialChart';
import RiskMetricsChart from '../../../../charts/esgCharts/RiskMetricsChart.component';
import BusinessInvolvementChart from '../../../../charts/esgCharts/BusinessInvolvmentChart.component';
import PrincipalAdverseImpactChart from '../../../../charts/esgCharts/PrincipalAdverseImpactChart.component';
import CarbonEmissionsChart from '../../../../charts/esgCharts/CarbonEmissionsChart.component';
import Article9Chart from '../../../../charts/esgCharts/Article9Icon';
import Article6Chart from '../../../../charts/esgCharts/Article6Icon';
import Article8Chart from '../../../../charts/esgCharts/Article8Icon';
import {
  percentageToTwoDecimalPlacesNoHundred,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import { Status } from '../../../../../types/redux/data/dataTypes';
import { Link } from 'react-router-dom';
import { esgVar } from './OverviewPanel.component';
import { Typography } from '@mui/material';
import { specifyStatus } from '../../../../../utilities/generalMappings';

interface ESGScoresPanelProps {
  data: any;
  fundId: string;
}

interface ArticleChartProps {
  article: string;
  articleStatus: Status;
}

interface ESGScoresPanelData {
  environmental: number;
  social: number;
  corporateGovernance: number;
  esgRiskMetric: number;
  businessInvolvement: number;
  principalAdverseImpact: number;
  article: string;
  articleStatus: string;
  observedArticle: string;
  carbonMetrics: number;
  package: string;
}

function buildESGScoresPanelData(data: any): ESGScoresPanelData {
  const panelData: ESGScoresPanelData = {
    environmental: 0,
    social: 0,
    corporateGovernance: 0,
    esgRiskMetric: 0,
    businessInvolvement: 0,
    principalAdverseImpact: 0,
    article: '',
    articleStatus: '',
    observedArticle: '',
    carbonMetrics: 0,
    package: 'esg_premium_package', // We will default to premium if no information on the packaged is provided
  };

  if (!data.data.length) return panelData;
  if (!('portfolio_scores' in data.data[0])) return panelData;
  if (!('sfdr_page' in data.data[0])) return panelData;
  if (!('tcfd' in data.data[0])) return panelData;

  const scoresData = data.data[0];

  // Check the ESG Version Number.
  const version = scoresData.version;
  // Get scores for version 2
  if (version === 3) {
    panelData.environmental = scoresData.portfolio_scores.E_Score;
    panelData.social = scoresData.portfolio_scores.S_Score;
    panelData.corporateGovernance = scoresData.portfolio_scores.G_Score;
  } else {
    panelData.environmental = scoresData.portfolio_scores.gross.e;
    panelData.social = scoresData.portfolio_scores.gross.s;
    panelData.corporateGovernance = scoresData.portfolio_scores.gross.g;
  }

  // update the package if available
  if ('esg_subscription_package' in scoresData) {
    panelData.package = scoresData.esg_subscription_package;
  }

  // Check if the client has access to the premium package.
  if (panelData.package === 'esg_premium_package') {
    // These values are only available with the premium package
    panelData.businessInvolvement = scoresData.risksystem_leaves.leafs * 20.0;
    panelData.principalAdverseImpact =
      100.0 - scoresData.zero_emissions[0].metric;
    panelData.article =
      scoresData.sfdr_page.overview_information.reported_level.replace(
        'A_',
        'Article ',
      );
    panelData.observedArticle =
      scoresData.sfdr_page.overview_information.observed_level.replace(
        'A_',
        'Article ',
      );
    panelData.articleStatus = scoresData.sfdr_page.overview_information.status;
    panelData.carbonMetrics = scoresData.tcfd.total_carbon_emissions;
  }

  return panelData;
}

function getArticleIcon(article: string, articleStatus: string): ReactElement {
  switch (article) {
    case 'Article 6':
      return <Article6Chart status={specifyStatus(articleStatus)} />;
    case 'Article 8':
      return <Article8Chart status={specifyStatus(articleStatus)} />;
    case 'Article 9':
      return <Article9Chart status={specifyStatus(articleStatus)} />;
    default:
      return <Article6Chart status={specifyStatus(articleStatus)} />;
  }
}

const ESGOverviewPanel: FC<ESGScoresPanelProps> = (props) => {
  const scores = buildESGScoresPanelData(props.data);
  const articleIcon = getArticleIcon(scores.article, scores.articleStatus);

  return (
    <>
      <GridItem
        xs={scores.package === 'esg_premium_package' ? 3 : 4}
        card
        cardStyle={{ minHeight: '21.88rem' }}
      >
        <Link to={'/esg/environmental-esg'}>
          <div style={{ justifyContent: 'center' }}>
            <Typography
              variant="h1"
              align="center"
              style={{ marginBottom: '1.25rem', fontWeight: 100 }}
            >
              Environmental Score
            </Typography>
            <div style={{ height: '12.50rem', width: '12.50rem', margin: '0 auto' }}>
              <EnvironmentalChart value={scores.environmental} />
            </div>
            <Typography
              variant="h2"
              align="center"
              style={{ marginTop: '1.25rem' }}
            >
              {roundNumberToTwoDecimalPlaces(scores.environmental)}
            </Typography>
            <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
              {' '}
              / 100.00
            </Typography>
          </div>
        </Link>
      </GridItem>
      <GridItem
        xs={scores.package === 'esg_premium_package' ? 3 : 4}
        card
        cardStyle={{ minHeight: '21.88rem' }}
      >
        <Link to={'/esg/social-esg'}>
          <div style={{ justifyContent: 'center' }}>
            <Typography
              variant="h1"
              align="center"
              style={{ marginBottom: '1.25rem', fontWeight: 100 }}
            >
              Social Score
            </Typography>
            <div style={{ height: '12.50rem', width: '18.75rem', margin: '0 auto' }}>
              <SocialChart value={scores.social} />
            </div>
            <Typography
              variant="h2"
              align="center"
              style={{ marginTop: '1.25rem' }}
            >
              {roundNumberToTwoDecimalPlaces(scores.social)}
            </Typography>
            <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
              {' '}
              / 100.00
            </Typography>
          </div>
        </Link>
      </GridItem>
      <GridItem
        xs={scores.package === 'esg_premium_package' ? 3 : 4}
        card
        cardStyle={{ minHeight: '21.88rem' }}
      >
        <Link to={'/esg/corporate-governance-esg'}>
          <div style={{ justifyContent: 'center' }}>
            <Typography
              variant="h1"
              align="center"
              style={{ marginBottom: '1.25rem', fontWeight: 100 }}
            >
              Governance Score
            </Typography>
            <div style={{ height: '12.50rem', width: '12.50rem', margin: '0 auto' }}>
              <CorporateGovernanceChart value={scores.corporateGovernance} />
            </div>
            <Typography
              variant="h2"
              align="center"
              style={{ marginTop: '1.25rem' }}
            >
              {roundNumberToTwoDecimalPlaces(scores.corporateGovernance)}
            </Typography>
            <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
              {' '}
              / 100.00
            </Typography>
          </div>
        </Link>
      </GridItem>
      {scores.package === 'esg_premium_package' && (
        <GridItem xs={3} card cardStyle={{ minHeight: '21.88rem' }}>
          <Link to={'/esg/article-scanner'}>
            <div style={{ justifyContent: 'center' }}>
              <Typography
                variant="h1"
                align="center"
                style={{ marginBottom: '1.25rem', fontWeight: 100 }}
              >
                Article Scanner
              </Typography>
              <div
                style={{ height: '12.50rem', width: '12.50rem', margin: '0 auto' }}
              >
                {articleIcon}
              </div>
              <Typography
                variant="h2"
                align="center"
                style={{ marginTop: '1.25rem' }}
              >
                Stated Level: {scores.article}
              </Typography>
              <Typography variant="h2" align="center">
                Observed Level: {scores.observedArticle}
              </Typography>
            </div>
          </Link>
        </GridItem>
      )}
      {scores.package === 'esg_premium_package' && (
        <GridItem xs={3} card cardStyle={{ minHeight: '21.88rem' }}>
          <div style={{ justifyContent: 'center' }}>
            <Typography
              variant="h1"
              align="center"
              style={{ marginBottom: '1.25rem', fontWeight: 100 }}
            >
              ESG Risk Metrics
            </Typography>
            <div style={{ height: '12.50rem', width: '12.50rem', margin: '0 auto' }}>
              <RiskMetricsChart value={esgVar(props.data)} />
            </div>
            <Typography
              variant="h2"
              align="center"
              style={{ marginTop: '1.25rem' }}
            >
              {esgVar(props.data)
                ? percentageToTwoDecimalPlacesNoHundred(esgVar(props.data)) +
                  ' ESG-VAR'
                : '-'}
            </Typography>
          </div>
        </GridItem>
      )}
      {scores.package === 'esg_premium_package' && (
        <>
          <GridItem xs={3} card cardStyle={{ minHeight: '21.88rem' }}>
            <div style={{ justifyContent: 'center' }}>
              <Typography
                variant="h1"
                align="center"
                style={{ marginBottom: '1.25rem', fontWeight: 100 }}
              >
                Business Involvement Score
              </Typography>
              <div
                style={{ height: '12.50rem', width: '12.50rem', margin: '0 auto' }}
              >
                <BusinessInvolvementChart value={scores.businessInvolvement} />
              </div>
              <Typography
                variant="h2"
                align="center"
                style={{ marginTop: '1.25rem' }}
              >
                {roundNumberToTwoDecimalPlaces(scores.businessInvolvement)}
              </Typography>
              <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
                {' '}
                / 100.00
              </Typography>
            </div>
          </GridItem>
          <GridItem xs={3} card cardStyle={{ minHeight: '21.88rem' }}>
            <Link to={'/esg/principle-adverse-impact'}>
              <div style={{ justifyContent: 'center' }}>
                <Typography
                  variant="h1"
                  align="center"
                  style={{ marginBottom: '1.25rem', fontWeight: 100 }}
                >
                  Principal Adverse Impact
                </Typography>
                <div
                  style={{ height: '12.50rem', width: '12.50rem', margin: '0 auto' }}
                >
                  <PrincipalAdverseImpactChart
                    value={scores.principalAdverseImpact}
                  />
                </div>
                <Typography
                  variant="h2"
                  align="center"
                  style={{ marginTop: '1.25rem' }}
                >
                  {roundNumberToTwoDecimalPlaces(scores.principalAdverseImpact)}
                </Typography>
                <Typography
                  variant="h2"
                  align="center"
                  style={{ opacity: 0.3 }}
                >
                  {' '}
                  / 100.00
                </Typography>
              </div>
            </Link>
          </GridItem>

          <GridItem xs={3} card cardStyle={{ minHeight: '21.88rem' }}>
            <Link to={'/esg/carbon-emissions'}>
              <div style={{ justifyContent: 'center' }}>
                <Typography
                  variant="h1"
                  align="center"
                  style={{ marginBottom: '1.25rem', fontWeight: 100 }}
                >
                  Carbon Metrics / Paris Alignment
                </Typography>
                <div
                  style={{ height: '12.50rem', width: '10.62rem', margin: '0 auto' }}
                >
                  <CarbonEmissionsChart value={scores.carbonMetrics} />
                </div>
                <Typography
                  variant="h2"
                  align="center"
                  style={{ marginTop: '1.25rem' }}
                >
                  {roundNumberToTwoDecimalPlaces(scores.carbonMetrics)} tCO2
                </Typography>
              </div>
            </Link>
          </GridItem>
        </>
      )}
    </>
  );
};

export default ESGOverviewPanel;
